import {
  buildTrackingConfig,
  getPendoConfig,
} from '@workfront/mfe/plugins/pendo'

export default async function initializePendo() {
  if (await isPendoAllowedFromGainsight()) {
    return finalizeAndInitializePendo()
  }
}

export function handlePendoCheckForPublicPages(valueForNonPublicPage) {
  if (window.config?.publicToken) {
    console.warn(
      'Pendo is disabled for public pages, skipping Pendo installation.',
    )
    return false
  }
  return valueForNonPublicPage
}

function isPendoAllowedFromGainsight() {
  return System.import('@wf-mfe/unified-shell-bootstrapper').then(
    (shellModule) => {
      const { getIsUnifiedShellEnabled, isExcReady } = shellModule
      if (getIsUnifiedShellEnabled()) {
        return isExcReady
          .then(() => {
            const shellCanCollectPendo =
              window['shellGainsight']?.user.permissions
                ?.pendoUsageDataCollection ?? true
            return handlePendoCheckForPublicPages(shellCanCollectPendo)
          })
          .catch((err) => {
            console.error('Error getting ExC shellGainsight object', err)
          })
      } else {
        return handlePendoCheckForPublicPages(true)
      }
    },
  )
}

function finalizeAndInitializePendo() {
  return getPendoConfig()
    .then((config) => {
      if (config && !config.isPendoEnabled) {
        console.warn('Pendo is disabled, skipping Pendo installation.')
      } else if (config && !config.accountID) {
        console.warn('No Pendo Account ID set, skipping Pendo installation.')
      } else if (config && config.isPendoEnabled) {
        return appendScriptAndInvokePendo(config)
      } else {
        console.warn('No Pendo config, skipping Pendo installation.')
      }
    })
    .catch((err) => {
      console.error('Error getting pendoConfig', err)
    })
}

function appendScriptAndInvokePendo(config) {
  const { pendoAccountID } = config
  const pendoConfig = buildTrackingConfig(config)

  ;(function (p, e, n, d, o) {
    const src =
      'https://cdn.pendo.io/agent/static/' + pendoAccountID + '/pendo.js'
    if (e.querySelector(`script[src="${src}"]`)) {
      console.info(`pendo script already loaded, skipping script setup`)
      return
    }

    var v, w, x, y, z
    o = p[d] = p[d] || {}
    o._q = []
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad']
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0)),
            )
          }
      })(v[w])
    y = e.createElement(n)
    y.async = true
    y.src = src
    z = e.getElementsByTagName(n)[0]
    if (z) {
      z.parentNode.insertBefore(y, z)
    } else {
      e.head.appendChild(y)
    }
  })(window, document, 'script', 'pendo')
  window.pendo.initialize(pendoConfig)
}
