export default function initializeJumpSeat() {
  return System.import('@wf-mfe/api')
    .then((apiModule) => {
      const { wfetch } = apiModule
      // skip this API call for public pages
      if (window.config?.publicToken) {
        return Promise.resolve()
      }
      return wfetch(
        '/attask/api-internal/customer?action=getPackagingOptionValues&method=PUT',
      )
        .then((packagingOptions) => {
          if (packagingOptions.JumpSeatIntegration) {
            return getJumpSeatConfigData()
          }

          return Promise.resolve()
        })
        .catch(() => {
          return Promise.resolve()
        })
    })
    .catch(() => {
      return Promise.resolve()
    })
}

function getJumpSeatConfigData() {
  return Promise.resolve().then(() => {
    return System.import('@wf-mfe/api')
      .then((apiModule) => {
        // Get JumpSeat configuration data
        const { wfetch, getAuthHeaders } = apiModule
        return wfetch(
          '/jumpseat/api/jumpseat/v1/configuration',
          {
            method: 'GET',
            credentials: 'include',
            headers: getAuthHeaders(),
          },
          { preventAutoRedirect: true },
        ).then((response) => response.json())
      })
      .then((customerInfo) => {
        // Handle customerInfo and inject script
        if (customerInfo && customerInfo.isActive) {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = `${customerInfo.url}/aerospace?ref=${window.location.origin}`
          document.body.appendChild(script)
        }
        return Promise.resolve()
      })
      .catch(() => {
        return Promise.resolve()
      })
  })
}
